import React, {Component} from 'react'
import Helmet from 'react-helmet'
import {Link, graphql} from 'gatsby'
import { has } from 'lodash'
import PostCard from '../components/PostCard'
import config from '../../data/config'
import HeroBanner from '../components/Hero'

class TagRoute extends Component {
  render () {
    const posts = this.props.data.allMarkdownRemark.edges

    const tag = this.props.pageContext.tag
    const title = this.props.data.site.siteMetadata.title
    const totalCount = this.props.data.allMarkdownRemark.totalCount
    const tagHeader = `${totalCount} post${
      totalCount === 1 ? '' : 's'
    } tagged with “${tag}”`

    // const tagHeader = `${tag}`

    const websiteSchemaOrgJSONLD = {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: config.siteUrl,
      name: config.siteTitle,
      alternateName: config.siteTitleAlt ? config.siteTitleAlt : '',
    }

    const hero_settings = {
      size: 'is-medium',
      particle: false,
      images: '',
      css: '#ffafbd,#ffc3a0~#2193b0,#6dd5ed',
      font_color: '#fff',
    }

    return (
      <div>
        <Helmet>
          <title>Tags | Layer Cake Solutions</title>
          {/* Schema.org tags */}
          <script type='application/ld+json'>
            {JSON.stringify(websiteSchemaOrgJSONLD)}
          </script>
        </Helmet>

        <HeroBanner
          title={tagHeader}
          subtitle={''}
          hero_settings={hero_settings}
        />

        <section className='section is-feature-grey edge--top'>
          <div className='container'>
            <PostCard posts={posts} />
            <div className='buttons is-centered'>
              <Link to='/tags/' className='button is-rounded'>
                    Browse all tags
              </Link>
            </div>
          </div>
        </section>
      </div>

    )
  }
}

export default TagRoute

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          excerpt(pruneLength: 100)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            cover
            date(formatString: "MMMM DD, YYYY")
            tags
            hero_settings { 
              size
              particle
              images
              css
              font_color
              title_align
            }
          }
        }
      }
    }
  }
`
